@import "./src/assets/styles/abstracts";

.modal_everywhere_color{
  border: none !important;
  display: none !important;
}
.modal_everywhere_wrapper{
  backdrop-filter: blur(150px);
  display: flex;
  justify-content: center;
  align-content: center;
  height: rem(813);
  @include breakpoint("768px", "max") {
    height: 100%;
  }
  .modal_everywhere_content{
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    .modal_everywhere_content_wrapper{
      padding: 0 rem(284);
      @include breakpoint("768px", "max") {
        height: rem(300);
        overflow-y: scroll;
      }
      @include breakpoint("1300px", "max") {
        padding: 0 rem(20);
      }
      @include breakpoint("300px", "max") {
        padding: 0 rem(5);
      }
    }
    @include breakpoint("768px", "max") {
      display: block;
    }



    .modal_everywhere_img{
      display: flex;
      justify-content: center;
      align-content: center;
      margin-bottom: rem(60);
      @include breakpoint("1300px", "max") {
        margin-bottom: rem(20);
        margin-top: rem(20);
      }
    }
    .modal_everywhere_content_title{
      display: flex;
      justify-content: center;
      margin-bottom: rem(32);

      h1{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: rem(22);
        line-height: rem(28);
        color: $text_color;
        @include breakpoint("1300px", "max") {
          font-size: rem(16);
        }
      }
    }
    .modal_everywhere_content_desc{
      &:not(:last-child){
        margin-bottom: rem(16);
      }
      p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(20);
        text-align: center;
        color: $aboutColor;
        @include breakpoint("1300px", "max") {
          font-size: rem(13) !important;
        }
      }
      .modal_everywhere_content_warning{
        color: $main;
        font-weight: 600;
      }
    }
  }

}
.modal_everywhere_btn_wrapper{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: rem(90);
  background: #FFFFFF;
  box-shadow: 0px -3px 12px rgba(15, 126, 227, 0.07);
  border-radius: 4px;
  .modal_everywhere_btn{
    display: flex;
    align-content: center;
    button{
      background: $text_color;
      box-shadow: 0px 4px 20px rgba(15, 126, 227, 0.07);
      border-radius: $Bradius;
      width: rem(163);
      height: rem(50);
    }
  }
}
.modal__dialog--modal_wrapper{
  width: 70% !important;
  margin-top: rem(50) !important;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: center;
  @include breakpoint("992px", "max") {
    width: 90% !important;
    margin-top: rem(20) !important;
  }
  .custom_modal_div{
    height: rem(843);
    @include breakpoint("768px", "max") {
      height:rem(543);
    }

    text-align: center;
    display: flex;
    align-content: center;
  }
}

.modal_custom_overlay{
  display: flex ;
  align-items: center ;
}
