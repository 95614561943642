@import "../../assets/styles/abstracts";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  @include transition;
  visibility: hidden;
  opacity: 0;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &-overlay {
    overflow: auto;
    z-index: 1040;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(53, 56, 66, 0.6);
    .subscription__modal{
    
      .subscription__container{
        .subscription__header{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-top: rem(60);
          p{
            margin-bottom: rem(42);
            margin-top: rem(16);
            text-align: center;
            color: $text_color;
          }
        }
     .select_subscription{
      .subscription__select__all{
        display: flex;
        justify-content: center;
        align-items: center;
        label{
          width: rem(470);
          height: rem(58);
          border: rem(1) solid $aboutColor;
          border-radius: $Bradius;
          font-weight: $defaultWeight;
          font-size: $defaultSize;
          line-height: rem(23);
          color: $text_color;
          margin-bottom: rem(25);
        }
      }
      .subscription_active{
        background-color: $main;
        color: $white !important;
      }
     }
     .subscription_content{
      background-color: $bg_second_color;
      padding: rem(40);

      .subscription_container{
        .subscription_categories_list{
          .active_label{
            border: rem(1) solid $main !important;
          }
          display: flex;
          flex-wrap: wrap;
          .subscription_categories_list_item{
            position: relative;
            width: rem(240);
            height: rem(75);
            background-color: $white;
            box-shadow: $tab_sub;
            border-radius: $Bradius;
            margin: rem(26) rem(14) rem(12) rem(0);
            display: flex;
            align-items: center;
            border: rem(1) solid transparent;
            transition: all .3s ease;

            img{
              width: rem(32);
              height: rem(28);
              margin-right: rem(12);
              margin-left: rem(16);
              opacity: 0.5;
              transition: all .3s ease;
            }
            .active_img{
              opacity: 1;
            }
          }
        }
      }
     }
      }
      .modal_guide_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        padding: rem(20) 0 rem(20) 0;
       
        .guide_btn{
          width: rem(168);
          height: rem(50);
        }
        .disabled{
          background-color: $gray;
        }

      }
     }
    .custom_class {
      height: 42rem;
      position: fixed;
      width: 90%;
      right: 0;
    }
    .right__title{
      display: flex;
      align-items: center;
      img{
        margin-right: rem(7);
      }
      p{
        color: $main;
        font-weight: $lgWeight;
        font-size: $fontSizeSm;
      }
    }
    .guide_modal {
      width: 90%;
      .modal_content_container {
        overflow-y: scroll;
        height: rem(400);
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: $main ;
        }
        .modal_guide_content {
          margin: rem(40) rem(122);
          border: rem(1) solid $modal_guide_border;
          padding: rem(40) rem(80);
          p {
            width: 100%;
          }
        }
      }


      .guide_color {
        border-bottom: rem(1) solid $modal_guide_border;

        .modal__title {
          color: #000;

        }
      }
    }
  }

  &__dialog {
    margin: rem(28) auto;
    width: 90%;
    overflow: hidden;
    &--centered {
      display: flex;
      align-items: center;
      min-height: calc(100% - #{rem(16)});
    }
  }

  &--lg {
    max-width: rem(800);
  }

  &--xl {
    max-width: rem(1140);
  }

  &--md {
    max-width: rem(580);
  }

  &--sm {
    max-width: rem(300);
  }

  &__content {
    border-radius: rem(6);
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    outline: 0;
    margin: auto;
    z-index: 1051;

  }

  // header
  &__header {
    display: flex;
    justify-content: space-between;
    padding: rem(12) rem(26);
    border-bottom: 1px solid $modal-border;
    min-height: rem(64);

  }

  &__title {
    font-weight: 700;
    font-size: rem(20);
    letter-spacing: 1px;
    color: $modal-title;
    margin: 0;
    margin-top: rem(4);
    padding: 0;
  }

  &__close {
    float: right;
    font-size: rem(24);
    font-weight: 700;
    line-height: 1;
    color: $black;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  // body
  &__body {
    padding: rem(26) rem(26);

    p {
      font-size: rem(18);
    }
  }

  &__text {
    color: $modal-color;
    letter-spacing: 1px;
    font-size: rem(14);
    line-height: rem(22);
    padding: 0;
    margin: 0;
  }

  // footer
  &__footer {
    border-top: 1px solid $modal-border;
    padding: rem(26) rem(26);
    display: flex;
  }
}


