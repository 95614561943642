@import "../../assets/styles/abstracts";

.loading {
  background-color: #ffffff30;
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
  transition-duration: 0.3s;
  &__icon-wrapper {
    &__icon {
      font-size: rem(60);
      color: $main;
    }
  }
  &--show {
    visibility: visible;
    opacity: 1;
  }
  &--absolute {
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute !important;
    &__icon-wrapper {
      &__icon {
        font-size: rem(60);
        color: $main;
      }
    }
  }
}
