@import "../../assets/styles/abstracts/";
.faq_not_found_section{
    background-color: $white;
    border-radius: $Bradius;
    box-shadow: $tab_sub;
    height: rem(335);
    display: flex;
    align-items: center;
    justify-content: center;
    .faq_not_found{
        display: flex;
        flex-direction: column;
        align-items: center;
        .faq_not_found_img{
            margin-bottom: rem(36);
 
        }
        .faq_not_found_content{
            text-align: center;
            h4{
                margin-bottom: rem(10);
            }
        }
    }
}