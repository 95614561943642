/*google fonts | mulish font test */
//@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;900&display=swap');

.grecaptcha-badge{
  display: none !important;
}


body {
  font-family: "Mulish", sans-serif; // test
  background-color: $bg_color;
  font-size: 16px !important;
  margin: 0;
  padding: 0;
}
html{
  scroll-behavior: smooth;
}
*{
  font-family: "Mulish", sans-serif;
}
a {
  text-decoration: none;
  &:active,
  &:visited {
    color: inherit;
  }
}
img {
  max-width: 100%;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 3px $main;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $main;
}

.container {
  max-width: 1200px;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
textarea {
  resize: none;
}
.w-full{
  width: 100%;
}

iframe{display: none;}