@import "./../../assets/styles/abstracts";

.btn {
  text-shadow: none;
  font-size: rem(14);
  color: $main-button-text-color;
  cursor: pointer;
  background-color: $button-color;
  will-change: opacity, transform;
  border-radius: $border-radius;
  outline: none;
  border: none;
  @include transition(all, 0.3s, ease-out);

  &:hover {
    box-shadow: none;
  }

  &--primary {
    color: $button-text-color;
    background-color: $primary-button-color;
    border-color: $primary-button-color;
    // box-shadow: 0 10px 20px -10px $primary-button-color;
  }

  &--info {
    color: $button-text-color !important;
    background-color: $info-button-color;
    border-color: $info-button-color;
    box-shadow: 0 10px 20px -10px $info-button-color;
  }

  &--success {
    color: $button-text-color !important;
    background-color: $success-button-color;
    border-color: $success-button-color;
    box-shadow: 0 10px 20px -10px $success-button-color;
  }

  &--warning {
    color: $button-text-color !important;
    background-color: $warning-button-color;
    border-color: $warning-button-color;
    box-shadow: 0 10px 20px -10px $warning-button-color;
  }

  &--danger {
    color: $button-text-color !important;
    background-color: $danger-button-color;
    border-color: $danger-button-color;
    box-shadow: 0 10px 20px -10px $danger-button-color;
  }

  &--secondary {
    background-color: $white;
    border: 1px solid $borderColor;
    border-left: none;
    display: flex;
    align-items: center;
    width: rem(175);
    border-radius: $Bradius;
    color: $darkColor;
    // color: $button-text-color !important;
    // background-color: $secondary-button-color;
    // border-color: $secondary-button-color;
    // box-shadow: 0 10px 20px -10px $secondary-button-color;
  }

  &--dark {
    color: $button-text-color !important;
    background-color: $text_color;
    border-color: $text_color;
  }

  &--rounded {
    border-radius: rem(50) !important;
  }

  &--rounded-circle {
    height: rem(50);
    width: rem(160);
    padding: rem(8) rem(8);
  }

  &--size-sm {
    font-size: rem(11);
  }

  &--size-lg {
    font-size: rem(16);
  }
}
